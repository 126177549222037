// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
 apiKey: "AIzaSyBV6TnzuiUNLyivE9tJzkiIQ-b_bouDTxs",
    authDomain: "luigi-f45bd.firebaseapp.com",
    projectId: "luigi-f45bd",
    storageBucket: "luigi-f45bd.appspot.com",
    messagingSenderId: "528100941634",
    appId: "1:528100941634:web:ba0baca5bdd4138642f2db",
    measurementId: "G-H0S0ZTSL00"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };