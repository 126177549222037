import React from 'react';
import { Box, Flex, Text, Button, VStack, Center, Image, Stack, Heading } from '@chakra-ui/react';
import paisaje from './imagenes/uno.jpg';
import lem from './imagenes/lem.png';
import a from './imagenes/1.png';
import { db } from "../firebase";
import { collection, addDoc } from 'firebase/firestore';

function CardComponent({ foto, title, description, buttons, alignment, ...props }) {
  const guardarInstagramEnFirebase = async (instagramUsuario) => {
    try {
      const docRef = await addDoc(collection(db, "datos"), {
        instagram: instagramUsuario
      });
      console.log("Instagram guardado con ID: ", docRef.id);
    } catch (error) {
      console.error("Error al guardar el Instagram: ", error);
    }
  };

  const handleClick = async (linkform,link) => {
    const instagramUsuario = prompt("Clic arriba de la línea, escribe tu cuenta de instagram (o whatsapp) y da clic en aceptar");
    if (instagramUsuario !== null && instagramUsuario.trim() !== "") {
      await guardarInstagramEnFirebase(link+" "+instagramUsuario);
      window.location.href = linkform;
    }
  };
  
  
  
  

  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      overflow='hidden'
      
      ml={alignment === 'left' ? { sm: '0', md: '0' } : 0}
      mr={alignment === 'right' ? { sm: '0', md: '0' } : 0}
      {...props}
    >
      <Image
        objectFit='cover'
        maxW={{ base: '100%', sm: '250px' }}
        marginLeft="30px" 
        src={foto}
        alt={title}
      />
      <Stack spacing={4}>
        <Box p={4}>
          <Heading size='md'>{title}</Heading>
          <Text py='2'>{description}</Text>
        </Box>
        <Box p={4}>
          <Stack direction={{ base: 'column', sm: 'row' }} spacing={4}>
            {buttons.map((btn, index) => (
              <Button
              key={index}
              variant={btn.variant}
              backgroundColor={btn.backgroundColor}
              color={btn.color}
              onClick={() => window.open(btn.link, "_self")}
            >
              {btn.label}
            </Button>
            ))}
            {/* Botón para activar el prompt y guardar el Instagram */}
            {buttons.map((btn, index) => (
            <Button 
            key={index} 
            variant={btn.variant} 
            backgroundColor={btn.backgroundColor} 
            color={btn.color}
            onClick={() => handleClick(btn.linkform,btn.link)}
            >
              Postular
            </Button>
            ))}
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

function Campamentos() {
  return (
    <Box>
      {/* Sección superior con imagen de fondo */}
      <Box
        position="relative"  // Necesario para posicionar el pseudo-elemento
        height={["300px", "450px"]}
        color="white"
        py={4}
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${paisaje})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          filter: 'brightness(55%)',  // Oscurece la imagen de fondo
          zIndex: -1  // Asegura que el contenido esté encima del fondo
        }}
      >
        <Center height="100%">
          <VStack spacing={2}>
            <Text fontSize={["2xl", "5xl"]} fontWeight="bold">
              PROGRAMAS
            </Text>
          </VStack>
        </Center>
      </Box>

      {/* Texto llamativo */}
      <Center mt={6} mb={6}>
        <Text fontSize={["xl", "2xl"]} fontWeight="semibold" textAlign="center">
          ¡Súmate a nuestras experiencias únicas!
        </Text>
      </Center>

      {/* Sección amarilla */}
      <Box backgroundColor="#FFDE59" py={4}>
        <CardComponent 
          foto={lem}
          title="Lemniscato: ¡Postulá para que el próximo verano vivas la aventura de tu vida!"
          description="¡Súmate a nuestro campamento virtual para jóvenes apasionados por las STEM (ciencia, tecnología, ingeniería y matemática) de 18 a 29 años!

          Experimentarás 3 a 5 días emocionantes, con la oportunidad de conectar con personas de diversas áreas del conocimiento. Sumérgete en actividades divertidas mientras adquieres herramientas útiles para el futuro. Conoce las distintas herramientas digitales que usaremos para emular el hotel en el que te hospedarás con tus nuevos amigos. Cada día, nos conectaremos mañana, tarde y noche para fortalecer esta sensación de cercanía.
          
          ¡No pierdas la oportunidad y postúlate ahora para ser parte de esta experiencia única!"
          buttons={[
            { 
                label: "Saber más", 
                variant: "solid", 
                backgroundColor: "white", 
                color: "black",
                link: "/Lemniscato",
                linkform:"https://forms.gle/mJdv4DTzeGE4e86g6"
            }
          ]}
          alignment="left"
        />
      </Box>

      {/* Sección blanca inferior */}
      <Box backgroundColor="#white" py={4}>
        <CardComponent 
          foto={a}
          title="NexCognita: ¡Únete a la comunidad activa de amantes del aprendizaje que cambiará tu vida!"
          description="Es un programa de un año de duración, ideal para potenciar tu vida personal y tu carrera profesional. Te sentirás parte de una comunidad activa de apasionados por las STEM (ciencia, ingeniería, tecnología y matemática) y amantes del aprendizaje. Desconecta de la rutina cada 3 semanas y sumérgete en un mundo de aprendizaje, conexión y emoción en el conocerás a personas increíbles, adquirirás habilidades valiosas y vivirás momentos inolvidables."
          buttons={[
            { 
                label: "Saber más", 
                variant: "solid", 
                backgroundColor: "#FFDE59", 
                color: "black",
                link: "/Nexcognita",
                linkform:"https://forms.gle/AJtGf1xzNyBc4Z869"
            }
          ]}
          alignment="left"
        />
      </Box>
    </Box>
  );
}

export default Campamentos;
