
import React, { useState, useEffect } from 'react';
import { Box, Center, Image, Heading, Grid, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Icon, Text, Flex, Button, Stack} from '@chakra-ui/react';
import { FaYoutube, FaInstagram, FaTiktok } from 'react-icons/fa';
import fondo from './imagenes/fondo.png';
import a from './imagenes/1.png';
import b from './imagenes/2.png';
import c from './imagenes/3.png';
import d from './imagenes/4.png';
import e from './imagenes/5.png';
import f from './imagenes/6.png';
import g from './imagenes/7.png';
import h from './imagenes/8.png';
import i from './imagenes/9.png';
import j from './imagenes/10.png';
import k from './imagenes/11.png';
import { db } from "../firebase";
import { collection, addDoc } from 'firebase/firestore';

function CardComponent({ foto, title, description, buttons, alignment, ...props }) {
  const guardarInstagramEnFirebase = async (instagramUsuario) => {
    try {
      const docRef = await addDoc(collection(db, "datos"), {
        instagram: instagramUsuario
      });
      console.log("Instagram guardado con ID: ", docRef.id);
    } catch (error) {
      console.error("Error al guardar el Instagram: ", error);
    }
  };

  const handleClick = async (linkform,link) => {
    const instagramUsuario = prompt("Clic arriba de la línea, escribe tu cuenta de instagram (o whatsapp) y da clic en aceptar");
    if (instagramUsuario !== null && instagramUsuario.trim() !== "") {
      await guardarInstagramEnFirebase(link+" "+instagramUsuario);
      window.location.href = linkform;
    }
  };
  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      overflow='hidden'
      ml={alignment === 'left' ? { sm: '0', md: '0' } : 0}
      mr={alignment === 'right' ? { sm: '0', md: '0' } : 0}
      justifyContent="center"
      {...props}
    >
        <Box p={4}>
          <Stack  spacing={4}> 
            {/* Botón para activar el prompt y guardar el Instagram */}
            {buttons.map((btn, index) => (
            <Button 
            key={index} 
            variant={btn.variant} 
            backgroundColor={btn.backgroundColor} 
            color={btn.color}
            fontSize="4xl" // Tamaño personalizado
            padding="30px"
            onClick={() => handleClick(btn.linkform,btn.link)}
        >
            Postular / Más Info
        </Button>
            ))}
          </Stack>
        </Box>
    </Flex>
  );
}

function Miembro({ imagen, nombre, descripcion}) {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" m={3} maxWidth="300px" cursor="pointer" >
      <Image src={imagen} alt={nombre} height="300px" width="300px" objectFit="cover" />
      <Box p="6">
        <Heading fontSize="xl">{nombre}</Heading>
        <Box mt={4}>{descripcion}</Box>
      </Box>
    </Box>
  );
}

function Quienessomos() {
  const [miembros, setMiembros] = useState([
      { imagen: b, nombre: 'Crearás vínculos ​sólidos y duraderos.', descripcion: 'Personas que te sorprenderán, ​conversaciones reales, consejos muy ​valiosos, amistades que te ​acompañarán todo el año, te darás ​cuenta de lo importante que son las ​relaciones de calidad para alcanzar ​una vida feliz y, cuando menos te lo ​esperes, ya tendrás más de una nueva.', socialLinks: [{ icon: FaInstagram, link: 'https://instagram.com/usuario1' }, { icon: FaYoutube, link: 'https://youtube.com/usuario1' }] },
      { imagen: c, nombre: 'Aprenderás como ​nunca.', descripcion: 'Conocimientos interesantes, ​herramientas útiles, desafíos ​increíbles,  trabajo en equipo, ​oratoria y tu comunicación efectiva, ​la oportunidad de potenciarte como ​persona y profesional, tu ​pensamiento crítico a prueba y, ​podríamos seguir contándote, pero ​mejor postulá y vivilo.', socialLinks: [{ icon: FaInstagram, link: 'https://instagram.com/usuario1' }, { icon: FaYoutube, link: 'https://youtube.com/usuario1' }] },
      { imagen: d, nombre: 'Un viaje que llegará a lo más ​profundo de tus emociones.', descripcion: 'Risas contagiosas, momentos que te ​harán reflexionar, llorarás de la ​emoción. Pero ¿Por qué esto es ​relevante? En los momentos más ​emocionales, la plasticidad cerebral ​se dispara, por consiguiente, hay más ​facilidad para absorber aprendizajes ​y almacenar recuerdos en relaciones ​nuevas… las emociones son el ​condimento secreto para que esta ​aventura te marque de por vida.', socialLinks: [{ icon: FaInstagram, link: 'https://instagram.com/usuario1' }, { icon: FaYoutube, link: 'https://youtube.com/usuario1' }] },
      { imagen: e, nombre: 'Proyecto anual.', descripcion: 'En caso de animarte, podrás ​participar en la elaboración de un ​proyecto STEM con apasionados de ​diversas áreas, o en el diseño de un ​producto o solución a un problema de ​la sociedad, o en la realización de una ​investigación que responda a la ​pregunta más importante de tu vida. ​¿Estás listo para este año?', socialLinks: [{ icon: FaInstagram, link: 'https://instagram.com/usuario1' }, { icon: FaYoutube, link: 'https://youtube.com/usuario1' }] },
      { imagen: f, nombre: 'Encuentros ​presenciales.', descripcion: 'El programa contará con encuentros ​presenciales sorpresas en los que ​deberás cumplir desafíos con la ​ayuda de los participantes de tu ​zona, culminando en una divertida ​juntada de amigos.', socialLinks: [{ icon: FaInstagram, link: 'https://instagram.com/usuario1' }, { icon: FaYoutube, link: 'https://youtube.com/usuario1' }] },
      { imagen: g, nombre: 'Red de contactos ​internacional.', descripcion: 'Adquirirás una red latinoamericana de ​contactos de diversas ramas STEM y ​quienes siempre estarán dispuestos a ​darte una mano en un futuro.', socialLinks: [{ icon: FaInstagram, link: 'https://instagram.com/usuario1' }, { icon: FaYoutube, link: 'https://youtube.com/usuario1' }] },
      { imagen: h, nombre: 'Sesiones de ​productividad.', descripcion: 'Un espacio donde la productividad se ​fusiona con la conexión humana. En ​cada sesión, te sumergirás en ​proyectos personales mientras te ​motivas y te comprometes a avanzar ​con la presencia y el apoyo de los ​demás participantes. Esto aumenta ​las probabilidades de que cumplas ​con tu objetivo y te otorga más ​energía para hacerlo.', socialLinks: [{ icon: FaInstagram, link: 'https://instagram.com/usuario1' }, { icon: FaYoutube, link: 'https://youtube.com/usuario1' }] },
      { imagen: i, nombre: 'Serás autor de tu ​propia aventura.', descripcion: 'Te acompañarán coordis muy ​amigables, quienes siempre estarán ​atentos a tus deseos, intereses y ​sugerencias para que cada encuentro ​al que asistas sea realmente valioso.', socialLinks: [{ icon: FaInstagram, link: 'https://instagram.com/usuario1' }, { icon: FaYoutube, link: 'https://youtube.com/usuario1' }] },
      { imagen: j, nombre: '¿Tiene costo?', descripcion: 'Para ver toda la información sobre el costo, las becas y las bases y condiciones del programa, hacé clic en "Postular/Más info".', socialLinks: [{ icon: FaInstagram, link: 'https://instagram.com/usuario1' }, { icon: FaYoutube, link: 'https://youtube.com/usuario1' }] }
    ]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMemberClick = (member) => {
    setSelectedMember(member);
    setIsModalOpen(true);
  };

  return (
    <Box>
      <Box
        bgImage={fondo}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        height={["300px", "450px"]}
        color="white"
        py={4}
      >
      </Box>
      <Box
      bgPosition="center"
      >
        <Center height="100%">
          <Text fontSize={["2xl", "5xl"]} fontWeight="bold" textAlign="center"> 
          Unite a un año con una comunidad ​apasionada por las STEM: nuevos amigos, ​aprendizaje y mucha diversión
          </Text>
        </Center>
      </Box>
      <Box backgroundColor="#white" py={4}>
        <CardComponent 
          
          
          description="NexCognita, un programa de un año, principalmente virtual, dirigido a jóvenes de entre 18 y 29 años que quieran ser parte de una comunidad activa de apasionados por el aprendizaje o por alguna rama STEM (ciencia, tecnología, ingeniería, matemática)."
          buttons={[
            { 
                label: "Saber más", 
                variant: "solid", 
                backgroundColor: "#00DBFF", 
                color: "black",
                link: "/Nexcognita",
                linkform:"https://forms.gle/AJtGf1xzNyBc4Z869"
            }
          ]}
          alignment="center"
        />
      </Box>
      <Center>
        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={6}>
          {miembros.map((miembro, index) => (
            <Miembro key={index} {...miembro} onOpen={() => handleMemberClick(miembro)} />
          ))}
        </Grid>
      </Center>

      {selectedMember && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedMember.nombre}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Image src={selectedMember.imagen} alt={selectedMember.nombre} mb={4} />
              {selectedMember.descripcion}
              <Box mt={4}>
                {selectedMember.socialLinks.map((link, idx) => (
                  <Icon as={link.icon} mr={3} cursor="pointer" onClick={() => window.open(link.link, '_blank')} key={idx} />
                ))}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      <Box backgroundColor="#white" py={4}>
        <CardComponent 
          
          
          description="NexCognita, un programa de un año, principalmente virtual, dirigido a jóvenes de entre 18 y 29 años que quieran ser parte de una comunidad activa de apasionados por el aprendizaje o por alguna rama STEM (ciencia, tecnología, ingeniería, matemática)."
          buttons={[
            { 
                label: "Saber más", 
                variant: "solid", 
                backgroundColor: "#00DBFF", 
                color: "black",
                link: "/Nexcognita",
                linkform:"https://forms.gle/AJtGf1xzNyBc4Z869"
            }
          ]}
          alignment="center"
        />
      </Box>
      <Box
      bgPosition="center"
      >
        <Center height="100%">
          <Text fontSize={["4xl", "7xl"]} fontWeight="extrabold" textAlign="center"> 
          ¿Qué es NexCognita?
          </Text>
        </Center>
      </Box>
      <Box
      bgPosition="center"
      >
        <Center height="100%">
          <Text fontSize={["1xl", "4xl"]} fontWeight="normal" textAlign="left" padding="15px"> 
          NexCognita, un programa de un año, ​principalmente virtual, dirigido a jóvenes de ​entre 18 y 29 años que quieran ser parte de ​una comunidad activa de apasionados por el ​aprendizaje o por alguna rama STEM (ciencia, ​tecnología, ingeniería, matemática).
          </Text>
        </Center>
      </Box>
      <img src={k} style={{ width: '25%', marginLeft: "50%" }} />
      <Box
      bgPosition="center"
      >
        <Center height="100%">
          <Text fontSize={["1xl", "4xl"]} fontWeight="normal" textAlign="left" padding="15px"> 
          Como sabemos que tal vez tengas un agenda ​muy ocupada, decidimos que el programa se ​desarrollará en encuentros que serán los días ​Domingo, cada tres semanas. ¿Re accesible, ​no?
          </Text>
        </Center>
      </Box>
      <Box
      bgPosition="center"
      >
        <Center height="100%">
          <Text fontSize={["1x1", "4xl"]} fontWeight="normal" textAlign="left" padding="15px"> 
          Cada encuentro contará con una sesión a la ​mañana, una sesión a la tarde y una sesión ​más distendida a la noche. También habrá ​sesiones optativas extras para los que más ​cariño le tengan al programa. Ah, y casi lo ​olvido, todo esto pasará en una isla virtual. ​¿Isla virtual? Sep, vas a tener que postular ​para verla.
          </Text>
        </Center>
      </Box>
      <Box backgroundColor="#white" py={4}>
        <CardComponent 
          
          
          description="NexCognita, un programa de un año, principalmente virtual, dirigido a jóvenes de entre 18 y 29 años que quieran ser parte de una comunidad activa de apasionados por el aprendizaje o por alguna rama STEM (ciencia, tecnología, ingeniería, matemática)."
          buttons={[
            { 
                label: "Saber más", 
                variant: "solid", 
                backgroundColor: "#00DBFF", 
                color: "black",
                link: "/Nexcognita",
                linkform:"https://forms.gle/AJtGf1xzNyBc4Z869"
            }
          ]}
          alignment="center"
        />
      </Box>
    </Box>
  );
}

export default Quienessomos;
