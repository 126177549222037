import React from 'react';
import { Box, Flex, Text, Button, VStack, Center, Input, Textarea, FormLabel, FormControl } from '@chakra-ui/react';
import paisaje from './imagenes/uno.jpg';

function Contacto() {
  return (
    <Box>
      {/* Sección superior con imagen de fondo */}
      <Box
        position="relative"  // Necesario para posicionar el pseudo-elemento
        height={["300px", "450px"]}
        color="white"
        py={4}
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${paisaje})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          filter: 'brightness(55%)',  // Oscurece la imagen de fondo
          zIndex: -1  // Asegura que el contenido esté encima del fondo
        }}
      >
        <Center height="100%">
          <VStack spacing={2}>
            <Text fontSize={["2xl", "5xl"]} fontWeight="bold">
              CONTACTO
            </Text>
          </VStack>
        </Center>
      </Box>

      {/* Sección de texto llamativo */}
      <Box backgroundColor="white" py={4} textAlign="center">
        <Text fontSize={["xl", "3xl"]} mb={6} textAlign="center"> 
          ¿Tenés una propuesta, sugerencia o agradecimiento? ¡Contactanos!
        </Text>
        
        {/* Formulario */}
        <Flex direction="column" maxW="600px" mx="auto" spacing={4}>
          <FormControl id="name" isRequired>
            <FormLabel>Nombre</FormLabel>
            <Input placeholder="Tu nombre" />
          </FormControl>
          
          <FormControl id="subject" isRequired>
            <FormLabel>Asunto</FormLabel>
            <Input placeholder="Asunto de tu mensaje" />
          </FormControl>
          
          <FormControl id="email" isRequired>
            <FormLabel>Correo</FormLabel>
            <Input placeholder="Tu correo electrónico" type="email" />
          </FormControl>
          
          <FormControl id="message" isRequired>
            <FormLabel>Mensaje</FormLabel>
            <Textarea placeholder="Tu mensaje" rows={5} />
          </FormControl>
          
          <Button variant="solid" colorScheme="yellow" alignSelf="center" mt={4} backgroundColor="#FFDE59">
            Enviar
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}

export default Contacto;

