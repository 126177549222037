import { Box, Circle, Flex, Heading, Text, VStack, HStack, Image, AspectRatio, Grid, Stack, Button } from "@chakra-ui/react";

import paisaje from './imagenes/portada.png';
import { db } from "../firebase";
import { collection, addDoc } from 'firebase/firestore';

function CardComponent({ foto, title, description, buttons, alignment, ...props }) {
  const guardarInstagramEnFirebase = async (instagramUsuario) => {
    try {
      const docRef = await addDoc(collection(db, "datos"), {
        instagram: instagramUsuario
      });
      console.log("Instagram guardado con ID: ", docRef.id);
    } catch (error) {
      console.error("Error al guardar el Instagram: ", error);
    }
  };

  const handleClick = async (linkform,link) => {
    const instagramUsuario = prompt("Clic arriba de la línea, escribe tu cuenta de instagram (o whatsapp) y da clic en aceptar");
    if (instagramUsuario !== null && instagramUsuario.trim() !== "") {
      await guardarInstagramEnFirebase(link+" "+instagramUsuario);
      window.location.href = linkform;
    }
  };
  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      overflow='hidden'
      ml={alignment === 'left' ? { sm: '0', md: '0' } : 0}
      mr={alignment === 'right' ? { sm: '0', md: '0' } : 0}
      justifyContent="center"
      {...props}
    >
        <Box p={4}>
          <Stack  spacing={4}> 
            {/* Botón para activar el prompt y guardar el Instagram */}
            {buttons.map((btn, index) => (
            <Button 
            key={index} 
            variant={btn.variant} 
            backgroundColor={btn.backgroundColor} 
            color={btn.color}
            fontSize="4xl" // Tamaño personalizado
            padding="30px"
            onClick={() => handleClick(btn.linkform,btn.link)}
        >
            Postular / Más Info
        </Button>
            ))}
          </Stack>
        </Box>
    </Flex>
  );
}

const YouTubeVideo = ({ src }) => {
  return (
    <AspectRatio ratio={16 / 9}>
      <iframe
        title="YouTube video player"
        src={src}
        allowFullScreen
        style={{ width: '100%', height: '100%' }}
      ></iframe>
    </AspectRatio>
  );
};

function Lemniscato() {
  return (

    <VStack spacing="8" align="center" w="100%">
    {/* Foto como fondo */}
    <Box position="relative" w="100%" h={['40vh', '50vh']} mb={['4', '8']}> {/* Ajusta el espacio entre la imagen y el título */}
      <Image src={paisaje} alt="Lemniscato" w="100%" h="100%" objectFit="cover" borderRadius="md" />
    </Box>

    <Box backgroundColor="#white" py={4}>
        <CardComponent 
          
          
          description="NexCognita, un programa de un año, principalmente virtual, dirigido a jóvenes de entre 18 y 29 años que quieran ser parte de una comunidad activa de apasionados por el aprendizaje o por alguna rama STEM (ciencia, tecnología, ingeniería, matemática)."
          buttons={[
            { 
                label: "Saber más", 
                variant: "solid", 
                backgroundColor: "#FF923A", 
                color: "black",
                link: "/Lemniscato",
                linkform:"https://forms.gle/mJdv4DTzeGE4e86g6"
            }
          ]}
          alignment="center"
        />
      </Box>

    {/* Título y subtítulo en el centro */}
    <Flex direction="column" align="center" justify="center"  px={4} > {/* Ajusta el espacio entre el título y el texto posterior */}
      <Heading size="lg" textAlign="center" color="black" mb={['4', '8']}>¿Qué es el Lemniscato?</Heading>
      <Box maxW="800px">
        <Text color="black" fontSize={['sm', 'md', 'lg']} textAlign="left">
          Imagínate hospedándote en un hotel completamente virtual lleno de personas tan apasionadas como tú. Visualízate entrando a un parque digital para participar de la sesión de la mañana, un encuentro en donde tendrás que poner todas tus ganas de aprender para enfrentar desafíos en donde desarrollarás la escucha activa para una comunicación realmente efectiva, en donde pondrás a prueba tu pensamiento crítico y tu capacidad de trabajar en equipo, en donde desarrollarás herramientas de comunicación tan valiosas como el storytelling y la oratoria, en donde llevarás tu productividad al siguiente nivel y en donde tendrás conversaciones reales, genuinas, conversaciones que te harán sentir como nunca antes te has sentido, conversaciones en las que conectarás realmente. Y luego, cuando todo haya terminado, abrirás los ojos y te darás cuenta de que ya eres parte de una comunidad de más de 100 jóvenes de toda América Latina tan increíbles como tú. Todo eso, todo ese conjunto de emociones, de alegrías y llantos, de aprendizajes y conexiones, todo eso, es el Lemniscato. ¿Te sumas?
        </Text>
      </Box>
    </Flex>

    {/* Otro "¿Por qué?" con 3 items */}
    <VStack spacing="8" align="center" w="100%" px={['4', '8']} py={['8', '12']} bg="gray.100" borderRadius="md">
      <Heading size="lg" mb={['4', '8']} textAlign="center">¿Por qué participar en el Lemniscato?</Heading>
      <Box w="100%">
        <Box bg="#FF923A" p="4" borderRadius="md" width="100%">
          <Text><strong>Aprenderás:</strong> Desarrollarás habilidades en áreas clave para tu crecimiento como persona y tu futuro como profesional.</Text>
        </Box>
        <Box bg="white" p="4" borderRadius="md" width="100%">
          <Text><strong>Conocerás personas:</strong> Generarás conexiones y amistades de gran valor.</Text>
        </Box>
        <Box bg="#FF923A" p="4" borderRadius="md" width="100%">
          <Text><strong>Comunidad:</strong> Luego de la experiencia tendrás el contacto de jóvenes apasionados y profesionales de áreas STEM, además de acceso total a una comunidad de más de 100 lemniscaters.</Text>
        </Box>
        <Box bg="white" p="4" borderRadius="md" width="100%">
          <Text><strong>Mucha emoción:</strong> Diseñamos tantas actividades que algunas se realizan hasta afuera de las sesiones, esto junto a toda una economía ficticia que hemos creado, mantiene la diversión de la aventura al máximo, por otro lado, quienes pasan por este programa desarrollan un sentido de pertenencia con el grupo con el que participan.</Text>
        </Box>
      </Box>
    </VStack>



    <Box backgroundColor="#white" py={4}>
        <CardComponent 
          
          
          description="NexCognita, un programa de un año, principalmente virtual, dirigido a jóvenes de entre 18 y 29 años que quieran ser parte de una comunidad activa de apasionados por el aprendizaje o por alguna rama STEM (ciencia, tecnología, ingeniería, matemática)."
          buttons={[
            { 
                label: "Saber más", 
                variant: "solid", 
                backgroundColor: "#FF923A", 
                color: "black",
                link: "/Lemniscato",
                linkform:"https://forms.gle/mJdv4DTzeGE4e86g6"
            }
          ]}
          alignment="center"
        />
      </Box>


      {/* Espacio para 3 videos */}
      <Box w="full" py={10} bg="gray.50">
        <Heading size="lg" mb={6} textAlign="center">
          Opiniones de participantes
        </Heading>

        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "2fr 2fr 2fr" }} gap={6} px={{ base: 4, lg: 20 }}>
        <Box gridColumn={{ base: 'span 1', md: 'span 1', lg: 'span 1' }}>
          <YouTubeVideo src="https://www.youtube.com/embed/4Lsgu8zQHKw" />
        </Box>
        <Box gridColumn={{ base: 'span 1', md: 'span 1', lg: 'span 1' }}>
          <YouTubeVideo src="https://www.youtube.com/embed/z3Hi4dUfCpg?si=KIUJPNtCyVjcgh9f" />
        </Box>
        <Box gridColumn={{ base: 'span 1', md: 'span 1', lg: 'span 1' }}>
          <YouTubeVideo src="https://www.youtube.com/embed/d4OkK_1OWFI" />
        </Box>
        <Box gridColumn={{ base: 'span 1', md: 'span 1', lg: 'span 1' }}>
          <YouTubeVideo src="https://www.youtube.com/embed/yLeNh5E5x8c?si=RzKJ2E7UIanEj5Nl" />
        </Box>
        <Box gridColumn={{ base: 'span 1', md: 'span 1', lg: 'span 1' }}>
          <YouTubeVideo src="https://www.youtube.com/embed/g5JZ2SZQqaQ?si=D7u1QTmvXf1S6f_7" />
        </Box>
      </Grid>
      </Box>

      <Box backgroundColor="#white" py={4}>
        <CardComponent 
          
          
          description="NexCognita, un programa de un año, principalmente virtual, dirigido a jóvenes de entre 18 y 29 años que quieran ser parte de una comunidad activa de apasionados por el aprendizaje o por alguna rama STEM (ciencia, tecnología, ingeniería, matemática)."
          buttons={[
            { 
                label: "Saber más", 
                variant: "solid", 
                backgroundColor: "#FF923A", 
                color: "black",
                link: "/Lemniscato",
                linkform:"https://forms.gle/mJdv4DTzeGE4e86g6"
            }
          ]}
          alignment="center"
        />
      </Box>

    </VStack >
  );
}

export default Lemniscato;
