import React, { useState, useEffect } from 'react';
import { db } from "../firebase"; // Asegúrate de importar la instancia de Firebase
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';

function DatosPage() {
  const [instagrams, setInstagrams] = useState([]);

  useEffect(() => {
    // Función para obtener los datos de Firestore
    const obtenerInstagramsDeFirestore = async () => {
      const datosCollection = collection(db, 'datos'); // Referencia a la colección 'datos'
      const datosSnapshot = await getDocs(datosCollection);
      const instagramsLista = [];
      datosSnapshot.forEach((doc) => {
        instagramsLista.push({ id: doc.id, instagram: doc.data().instagram }); // Añade el campo 'instagram' y 'id' al array
      });
      setInstagrams(instagramsLista);
    };

    obtenerInstagramsDeFirestore(); // Llamada a la función al cargar el componente
  }, []); // El segundo argumento [] significa que solo se ejecuta una vez (al montar el componente)

  const eliminarInstagram = async (id) => {
    try {
      await deleteDoc(doc(db, 'datos', id)); // Elimina el documento con el ID específico de la colección 'datos'
      setInstagrams(instagrams.filter(instagram => instagram.id !== id)); // Actualiza el estado eliminando el elemento con el ID correspondiente
    } catch (error) {
      console.error("Error al eliminar el instagram: ", error);
    }
  };

  return (
    <div>
      <h1>Instagrams de Firestore</h1>
      <ul>
        {instagrams.map((instagram, index) => (
          <li key={index}>
            {instagram.instagram}
            <button onClick={() => eliminarInstagram(instagram.id)}>Eliminar</button> {/* Botón de eliminación */}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DatosPage;
