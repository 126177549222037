import React from 'react';
import { Box, Text, Button, VStack, Center, Image, Stack, Heading, HStack } from '@chakra-ui/react';
import paisaje from './imagenes/uno.jpg';
import lemni from './imagenes/lemni.JPG';
import segu from './imagenes/segu.JPG';
import a from './imagenes/a.jpeg';
import b from './imagenes/b.JPG';
import c from './imagenes/c.jpeg';
import d from './imagenes/d.jpg';


function Donar() {
  return (
    <Box>
      {/* Sección superior con imagen de fondo */}
      <Box
        position="relative"  // Necesario para posicionar el pseudo-elemento
        height={["300px", "450px"]}
        color="white"
        py={4}
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${paisaje})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          filter: 'brightness(55%)',  // Oscurece la imagen de fondo
          zIndex: -1  // Asegura que el contenido esté encima del fondo
        }}
      >
        <Center height="100%">
          <VStack spacing={2}>
            <Text fontSize={["2xl", "5xl"]} fontWeight="bold">
              DONAR
            </Text>
          </VStack>
        </Center>
      </Box>

      {/* Sección de texto resaltado */}
      <Box py={4} textAlign="center">
        <Text fontSize={["xl", "3xl"]} fontWeight="semibold">
          Quiero ser parte
        </Text>
      </Box>

      {/* Sección de botones */}
      <Center py={6}>
        <HStack spacing={4} wrap="wrap">
          <Button
            variant="solid"
            colorScheme="yellow"
            width={["100%", "250px"]}
            height={["50px", "60px"]}
            backgroundColor="#FFDE59"
            onClick={() => window.open("http://link.mercadopago.com.ar/nacionciencia", "_blank")}
          >
            Donar una vez
          </Button>
          <Button
            variant="solid"
            colorScheme="yellow"
            width={["100%", "250px"]}
            height={["50px", "60px"]}
            backgroundColor="#FFDE59"
            onClick={() => window.open("https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848abc9fed018ac006585b0285", "_blank")}
          >
            Donar Mensualmente
          </Button>
        </HStack>
      </Center>


      {/* Sección de imágenes pequeñas */}
      <Stack
        direction={{ base: 'column', md: 'row' }}  // Columna en pantallas más pequeñas, fila en pantallas normales
        justifyContent="center"
        spacing={4}
        mx="auto"  // Centra horizontalmente en pantallas más grandes
      >
        <Image src={b} alt="Imagen pequeña" boxSize="250px" mx="auto" display="block" />
        <Image src={c} alt="Imagen pequeña" boxSize="250px" mx="auto" display="block" />
        <Image src={d} alt="Imagen pequeña" boxSize="250px" mx="auto" display="block" />

      </Stack>


      {/* Sección de beneficios de ser donante */}
      <Center py={6}>
        <Box backgroundColor="#FFDE59" width={["100%", "40%"]} py={4} px={4} rounded="md">
          <Heading as="h2" size="lg" fontWeight="bold" textAlign="center">
            Beneficios de ser donante
          </Heading>
          <Stack spacing={4} mt={6} textAlign="left">
            <Text fontSize={["md", "lg"]}>
              - Prioridad de ser seleccionado/a cuando apliques a nuestros programas.
            </Text>
            <Text fontSize={["md", "lg"]}>
              - Vía directa de comunicación con nosotros para que puedas hacernos las sugerencias y comentarios que quieras.
            </Text>
          </Stack>
        </Box>
      </Center>

      {/* Sección de imágenes pequeñas debajo */}
      <Stack
        marginBottom={45}
        direction={{ base: 'column', md: 'row' }}  // Columna en pantallas más pequeñas, fila en pantallas normales
        justifyContent="center"
        spacing={4}
        mx="auto"  // Centra horizontalmente en pantallas más grandes
      >
        <Image src={lemni} alt="Imagen pequeña" boxSize="250px" mx="auto" display="block" />
        <Image src={segu} alt="Imagen pequeña" boxSize="250px" mx="auto" display="block" />
        <Image src={a} alt="Imagen pequeña" boxSize="250px" mx="auto" display="block" />

      </Stack>
    </Box>
  );
}

export default Donar;
